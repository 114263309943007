.Nav {
  display: flex;
  position: fixed;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  /*height:2rem;*/
  height:34px;
  color: var(--white);
  font-family: 'Fira Code', Menlo, monospace;
  font-weight:normal;
  z-index: 999;
  background: var(--crt-background), linear-gradient(to top, hsl(169deg 15% 10%), hsl(280deg 2% 15%));
  background-size: var(--crt-size);
  /*border-radius: 0px 0px 5px 5px;*/
  opacity: 0.75;
}

.Link {
    opacity: 0.65;
    display: flex;
    align-items: center;
    margin: 1rem;
    font-size: 1.25rem;
}
.LinkActive {
    opacity: 0.9;
    display: flex;
    align-items: center;
    margin: 1rem;
    font-size: 1.25rem;
}
.Link:hover {
    opacity: 0.95;
    /*text-decoration: underline;*/
}

.navIconSVG {
    fill: var(--white);
    mix-blend-mode: screen;
    position:relative;
    z-index:5;
    margin-right: .5rem;
    backface-visibility: hidden;
    filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.5)) 
            drop-shadow(-1px 1px 0px rgba(0, 0, 0, 0.5)) 
            drop-shadow(1px -1px 0px rgba(0, 0, 0, 0.5)) 
            drop-shadow(-1px -1px 0px rgba(0, 0, 0, 0.5));
}

.crt {
  background: var(--crt-background);
  background-size: var(--crt-size);
}

@media only screen and (max-width: 414px) {
    .Nav {
      display:none;
    }
}