:root {
  --white: rgb(219, 219, 239);
  --white-alpha: rgb(219, 219, 239, 0.85);
  --amber: rgb(228 201 11 / 85%);
  --green: rgb(106, 198, 106);
  --blue: rgb(4, 93, 137);
  --f: hsl(210deg 100% 28%);
  --dark-grey: rgba(40,40,40,0.9);
  --dark-text: rgba(0,0,0,0.9);
  --blue-purple-gradient: linear-gradient(to right, rgb(228 80 255 / 30%), rgb(44 195 251 / 25%));
  --green-gradient: linear-gradient(to right, rgb(90 90 90 / 50%), rgb(89 219 116 / 35%));
  --blue-purple-gradient-splash: linear-gradient(to right, rgb(228 80 255 / 25%), rgb(44 195 251 / 20%));
  --green-gradient-splash: linear-gradient(to right, rgb(90 90 90 / 33%), rgb(89 219 116 / 30%));
  --dark-grey-gradient: radial-gradient(rgba(40,40,40,0.65), rgba(40,40,40,0.85));
  --dark-grey-gradient-highlight: radial-gradient(rgba(50,50,50,0.65), rgba(50,50,50,0.85));
  --crt-background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  --crt-size: 100% 2px, 9px 100%;
  --scrollbarWidth: none;
  /*
  --scrollbarBg: rgb(99, 102, 241);
  --scrollbarThumb: rgb(244, 63, 94);*/
}

::selection {
  background: hsl(300deg 44% 45% / 90%);
  mix-blend-mode: color-dodge;
}

html,body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.flex {
  display: flex;
  flex-direction: row;
}

input[type="range"]:disabled {
    opacity: 0.35;
}

.projectLink {
  background-color: var(--amber);
  color: var(--dark-text);
  mix-blend-mode: color-dodge;
  display:inline-block;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.projectLink:hover {
  color: var(--white)
}
.or {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}