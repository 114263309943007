.About {
  display: flex;
  flex-direction: column;
  /*align-items: center;
  justify-content: center;*/

  width: 80%;
  height: calc(100vh - 30px);
  margin-left: auto;
  margin-right: auto;

  color: var(--white);
  font-family: 'Fira Code', Menlo, monospace;
  font-weight: 600;
  font-size: 0.95rem;
  line-height: 1.5;
}
.About > div {
  margin: auto;
}

.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  mix-blend-mode: color-dodge;
}
@media screen and (max-height: 300px) and (min-width: 500px) {
  .content {
    font-size: 0.8rem;
  }
}

.picDiv {
  margin-top: auto;
  margin-bottom: auto;
}
.About img {
  width: 12rem;
  border-radius: 3px;
  padding-left: .25rem;
  /*rotate 120 - 240 */
  mix-blend-mode: color-dodge;
  animation: filter-animation 10s linear infinite;

  pointer-events: none;
  user-select: none;
}
@media only screen and (max-width: 750px) {
  .About img {
    display: none;
  }
}
@keyframes filter-animation {
    0% {
    filter: contrast(1.0) 
            sepia(0.13) 
            hue-rotate(185deg) 
            saturate(0.49) 
            brightness(0.925)
            opacity(0.9)
  }
  25% {
    filter: contrast(1.0) 
            sepia(0.13) 
            hue-rotate(210deg) 
            saturate(0.49) 
            brightness(0.925)
            opacity(0.9)
  }
  75% {
    filter: contrast(1.0) 
            sepia(0.13) 
            hue-rotate(160deg) 
            saturate(0.49) 
            brightness(0.925)
            opacity(0.9)
  }
  100% {
    filter: contrast(1.0) 
            sepia(0.13) 
            hue-rotate(185deg) 
            saturate(0.49) 
            brightness(0.925)
            opacity(0.9)
  }
}


.content {
  background-image: var(--dark-grey-gradient);
  /*background-color: var(--dark-grey);*/
  border: 2px solid var(--white);
  height: fit-content;
}
.content, .crt {
  border-radius:50px;
}

.words > span {
  display: block;
  margin-bottom: 1rem;
}

.crt {
  display: flex;
  background: var(--crt-background);
  background-size: var(--crt-size);
  z-index: 2;
  padding: 2.5rem 1.5rem;
}
/*
.highlight {
  background-color: var(--amber);
  color: rgba(0,0,0,0.9);
  mix-blend-mode: color-dodge;
}
*/
.highlight {
    background-color: var(--amber);
    color: rgba(0,0,0,0.9);
    mix-blend-mode: color-dodge;
}

a {
  color: var(--white);
}
.highlight:hover {
    background-color: var(--white);
}
/*
.contactLink:hover {
  border: 1px solid var(--white);
  border-radius: 3px;
  padding: -0.25rem;
  color: var(--white)
}
*/

.content {
  transform: skew(.1deg, .1deg);
}

@media only screen and (max-width: 414px) {
  .About {
    width: 90%;
    height: calc(100vh - 4rem);
  }
  .crt {
    padding: 1rem;
  }
}