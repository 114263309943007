.Canvas, .gradient {
  overflow:hidden;
  position: fixed; 
  top: 0px; 
  left: 0px;
  width: 100%;
  height: 100vh;
  max-width:100%;
  z-index: -2;
}
.Canvas:after {
  content: '';
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  background: radial-gradient(circle, transparent 50%, rgb(142, 28, 28) 150%);
  /*mix-blend-mode: difference;*/
  mix-blend-mode: saturation;
}
.gradient {
  /*background-image: radial-gradient(
    rgba(0, 0, 0, 0.7), rgba(255,255,255,0) 250%
  );*/
  background-image: radial-gradient(
    rgba(0, 0, 0, 0.12), black 120%
  );
  z-index: -1;
  mix-blend-mode: hard-light;
}