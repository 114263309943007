.MobileNav {
  width: fit-content;
  margin-left: auto;
  z-index: 1000;
  text-align:right;
}
.MobileNavOpen {
  height: 14rem;
  text-align:right;
  background: var(--crt-background), linear-gradient(to top, hsl(169deg 15% 10%), hsl(280deg 2% 15%));
  background-size: var(--crt-size);
  margin-left: auto;
  z-index: 1000;
  opacity: 0.75;
}

.mobileToggle{
  fill: var(--white);
  cursor: pointer;
  padding-right: 0.25rem;
}

.linkItem {
  font-family: 'Days One', sans-serif;  
  font-weight: bold;
  -webkit-text-stroke: 0.5px rgba(0, 0, 0, 0.8);
  width: fit-content;
  border-radius: 5px;
  font-size: 1.5rem;
  margin-left: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-right: 0.25rem;
}

@media only screen and (min-width: 415px) {
  .MobileNav, .MobileNavOpen {
    display: none;
  }
}