.Blog {
  display: flex;
  height: calc(100vh - 55px);
  margin-top: 40px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;

  color: var(--white);
  font-family: 'Fira Code', Menlo, monospace;
  font-weight: 600;
  font-size: 0.9rem;

  z-index: 200;

  isolation:isolate;
}
.Blog > div {
  margin: auto;
}
.blogLink {
    color: var(--white);
    font-style:italic;
    text-decoration: underline;
    /*background-color: var(--white);
    color: rgba(0,0,0,0.9);
    mix-blend-mode: color-dodge;*/
}
.blogLink:hover {
    text-decoration: underline;
    color: var(--amber);
    /*background-color: var(--amber);*/
  }
.content {
  background-image: var(--dark-grey-gradient);
  /*background-color: var(--dark-grey);*/
  height: fit-content;
  border: 2px solid var(--white);
}

.content, .crt, .card {
  border-radius:12px;
}
.thumbnail {
  border-radius:10px 10px 0px 0px;
}
.words {
  mix-blend-mode: color-dodge;
}
.crt {
  background: var(--crt-background);
  background-size: var(--crt-size);
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.cardWrap {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  /*flex-direction: row;*/
  align-items: center;
  /*justify-content: space-between;*/
}

.card {
  border: 2px solid var(--white);
  background-image: var(--dark-grey-gradient);
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: 20rem;
  max-width: 45rem;
  transform: skew(.1deg, .1deg);
}
.card:first-child {
    margin-left: 3rem;
  }
.card:last-child {
    margin-right: 3rem;
  }
.card:hover, .card:focus-within {
  border: 2px solid var(--amber);
  background-image: var(--dark-grey-gradient-highlight);
}
.description {
  font-size: 0.75rem;
  /*flex-basis: 50%;*/
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 0.75rem;
  text-align: left;
}

.thumbnail {
  width: 20rem;
  height: 12rem;
  opacity: 0.65;
}

.detailWrap {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  mix-blend-mode: color-dodge;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.title {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 1.1rem;
  font-style: italic;
  /*flex-basis: 50%;*/
  padding-left: 0.75rem;
  padding-bottom: 0.5rem;
}

@media only screen and (min-width: 414px) {
    .cardWrap {
        height: 100%;
      }
    .cardWrap::-webkit-scrollbar {
        display: none;
        background: transparent;
      }
}

@media only screen and (max-width: 414px) {
  .Blog {
    width: 95%;
    font-size: 0.85rem;
    margin-top: auto;
  }
  .Blog > div {
    margin-top: 2rem;
  }
  .title {
    padding-left: 0.25rem;
    padding-bottom: 0rem;
  }
  .description {
    padding-left: 0.25rem;
  }
  .cardWrap {
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
    flex-direction: column;
  }
  .card {
    margin-bottom: 2rem;
    width: calc(100vw - 4rem);
  }
  .card:first-child {
    margin-left: 1rem;
  }
  .card:last-child {
    margin-right: 1rem;
  }
  .thumbnail {
    width: calc(100vw - 4rem);
  }
}