.Project {
  display: flex;
  flex-direction: column;
  /*align-items: center;
  justify-content: center;*/

  /*width: 80%;*/
  height: calc(100vh - 74px);
  margin-top: 37px;
  margin-bottom: 37px;
  margin-left: auto;
  margin-right: auto;
  max-width: 45rem;


  color: var(--white);
  font-family: 'Fira Code', Menlo, monospace;
  font-weight: 600;
  font-size: 0.9rem;
}
.Project > div {
  margin: auto;
  width: 80%;
  /*min-width: 22rem;
  max-width: 26rem;*/
}


.content {
  background-image: var(--dark-grey-gradient);
  /*background-color: var(--dark-grey);*/
  border: 2px solid var(--white);
  height: fit-content;
}
.content, .crt {
  border-radius:30px;
}
.words {
  mix-blend-mode: color-dodge;
}


.crt {
  background: var(--crt-background);
  background-size: var(--crt-size);
  z-index: 2;
  /*padding: 1.5rem;*/
  padding: 1.5rem 1rem 1rem 1rem;
}

.highlight {
  background-color: var(--amber);
  color: var(--dark-text);
  mix-blend-mode: color-dodge;
}


.highlightWhite {
  background-color: var(--white-alpha);
  color: var(--dark-text);
  mix-blend-mode: color-dodge;
}


.projImg {
  width: 100%;
  opacity: 0.8;
}
.projImg, .Project h2 {
  max-width: 365px;
}
.Project h2 {
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.iframeContainer {
  width: 80%;
  opacity: 0.7;
  max-width: 365px;
  margin-left: auto;
  margin-right: auto;
  /*margin-top: 1.5rem;*/
  /*overflow: hidden;*/
  position: relative;
  /*filter: drop-shadow(1px 1px 2px rgba(25, 25, 25, 0.35));*/
}
.iframeContainer iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.iframeContainerCarousel {
    width: 100%;
    opacity: 0.75;
    padding-top: 50%;
    overflow: hidden;
    position: relative;
    filter: drop-shadow(-1px -1px 2px rgba(25, 25, 25, 0.35));
}
.iframeContainerCarousel iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.iframeContainerCarouselVertical {
    width: 100%;
    opacity: 0.75;
    padding-top: 125%;
    overflow: hidden;
    position: relative;
    filter: drop-shadow(-1px -1px 2px rgba(25, 25, 25, 0.35));
}
.iframeContainerCarouselVertical iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
  

.content {
  transform: skew(.1deg, .1deg);
}

@media only screen and (max-width: 414px) {
  .Project {
    width: 85%;
    height: auto;
  }
  .Project > div {
    width: 100%;
  }
  .content {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }
  .iframeContainerCarousel {
    padding-top: 43%;
  }
}