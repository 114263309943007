/* from inside carousel import */
.carousel-root {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.carousel-item-wrapper {
  position: relative;
  padding-top: 69%; /* Player ratio: 100 / (1280 / 720) */
}

.carousel-status {
  display: none
}

.carousel.carousel-slider {
  overflow: visible;
  margin-bottom: 1rem;
}

.carousel .control-dots {
  margin: -18px 0px
}

.carousel .control-prev.control-arrow {
  left: 13px;
}

.carousel .control-next.control-arrow {
  right: 13px;
}

.carousel.carousel-slider .control-arrow {
  margin-top: auto;
  margin-bottom: auto;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}


@media only screen and (max-width: 414px) {
  .carousel-root {
    width: 100%;
    padding-bottom: 0.5rem;
  }

}


.iframeContainer {
  width: 100%;
  opacity: 0.75;
  padding-top: 50%;
  overflow: hidden;
  position: relative;
  filter: drop-shadow(-1px -1px 2px rgba(25, 25, 25, 0.35));
}
.iframeContainer iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media only screen and (max-width: 414px) {
  .Video {
    width: 95%;
    height: calc(100vh - 4rem);
  }
  .crt {
    padding: 1rem;
    font-size: 0.85rem;  
  }
  .iframeContainer {
    padding-top: 43%;
  }
}