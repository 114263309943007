.Splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  max-height: calc(100vh - 2rem);
}
.Splash > div {
  width: 25.5rem;
}

a {
  text-decoration: none;
}

path {
  stroke: var(--white);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 22.5rem;
  height: calc(100vh - 18px);
  margin-left: auto;
  margin-right: auto;

  font-size: calc(10px + 2vmin);
  color: rgb(230, 230, 230);
}


.logo, .linkItem {
  font-family: 'Days One', sans-serif;  
  font-weight: bold;
  -webkit-text-stroke: 0.5px rgba(0, 0, 0, 0.8);
  width: fit-content;
  border: 3px solid var(--white);
  border-radius: 5px;
}

.logo {
  font-size: 33pt;
  line-height: 1.2;
  padding: 2px;
  background-image: var(--blue-purple-gradient-splash);
}

.logoInnerBorder {
  border: 2px solid var(--white);
  border-radius: 3px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.2rem;
  position: relative;
}

.linkWrap {
  width: 100%;
  margin-top: -2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*gap: 2rem;*/
}

.linkInnerWrap {
  display: flex;
  flex-direction: row;
  /*gap: 1.5rem;*/
}

.linkInnerWrap > a {
  margin-right: 1.2rem;
  margin-left: 1.2rem;
}

@media screen and (max-width: 414px) {
  .linkWrap {
    width: auto;
    gap: 0rem;
  }

  .linkInnerWrap {
    margin-top: 2rem;
    margin-left: -2px;
    flex-direction: column;
  }

  .linkInnerWrap > a {
    margin-right: 0rem;
    margin-left: 0rem;
    margin-bottom: 1.5rem;
  }
}



.linkItem {
  position:relative;
  width: 8rem;
  font-size: 27pt;
  padding: 2px;
  line-height: 1;
  background-image: var(--green-gradient-splash);
}
.linkItem > div {
  height: 2.4rem;
}
.crt {
  background: var(--crt-background);
  background-size: var(--crt-size);
  z-index: 2;
  border-radius: 5px;
}

.linkItemInnerWrap {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  isolation: isolate;
}


.logoHover, .linkItem:hover {
  background-size: 150% 150%;
  -webkit-animation: GradientSwirl 1s ease infinite;
  -moz-animation: GradientSwirl 1s ease infinite;
  -o-animation: GradientSwirl 1s ease infinite;
  animation: GradientSwirl 1s ease infinite;
}
.linkItem:hover {
  background-image: linear-gradient(to right, rgb(90 90 90 / 40%), rgb(89 219 116 / 40%));
}

@keyframes GradientSwirl {
  0%{background-position:0% 50%}
  50%{background-position:80% 30%}
  100%{background-position:0% 50%}
}

.linkText {
  color: rgba(0,255,0,0.9);
  mix-blend-mode: screen;
  position:relative;
  z-index: -1;
}
.linkTextBefore, .linkTextAfter {
  /*content: attr(title);*/
  position: absolute;
  mix-blend-mode: screen;
  transition: all 0.25s;
  /*-webkit-text-stroke: 0.25px rgba(0, 0, 0, 0.4);*/
}

.linkTextBefore {
  color: rgba(0,0,255,0.9);
  z-index: -1;
}
.itemHover:hover .linkItem .linkItemInnerWrap .linkTextBefore {
  transform: translate(-2px, -1px);
}

.linkTextAfter {
  color: rgba(255,0,0,0.9);
  right: 0;
  z-index: -1;
}
.itemHover:hover .linkItem .linkItemInnerWrap .linkTextAfter {
  transform: translate(2px, 1px);
} 


.itemHover > div {
  height: 2.98rem;
  overflow: hidden;
}


/* SVG 'trees' */

.treeHoriz {
  width: 87%;
  margin-top: -1px;
  stroke-dasharray: 600;
  stroke-dashoffset: 600;
}
.treeHorizFirst {
  animation: tree-draw-horiz 6s linear 0.2s forwards;
}
.treeHorizSecond {
  animation: tree-draw-horiz 1.5s linear 0.5s forwards;
}
@keyframes tree-draw-horiz {
  to {
    stroke-dashoffset: 0;
  }
}

.treeVert {
  display: none;
}
@media screen and (max-width: 414px) {
  .Splash > div {
    width: auto;
  }
  .treeHoriz {
    display: none;
  }
  .treeVert {
    display: block;
    height: 12.6rem;
    stroke-dasharray: 600;
    stroke-dashoffset: 600;
  }
  .treeVertFirst {
    animation: tree-draw-vert 2s linear forwards;
  }
  .treeVertSecond {
    animation: tree-draw-vert 2s linear 1s forwards;
  }
  .treeVertThird { 
    animation: tree-draw-vert 2s linear 1.3s forwards;
  }
  @keyframes tree-draw-vert {
    to {
      stroke-dashoffset: 0;
    }
  }
}



/* text glitch inspired by https://codepen.io/flipp3r/embed/GbjyKw?height=316&theme-id=0&default-tab=result */

.textGlitch {
  animation: glitch 2.75s linear infinite;
}

@media (prefers-reduced-motion: no-preference) {
  @keyframes glitch{
    2%,64%{
      transform: translate(1px,0) skew(0deg);
    }
    4%,60%,50%{
      transform: translate(0,0) skew(0deg);
    }
    62%{
      transform: translate(0,0) skew(1deg); 
    }
  }
  
  .textGlitch:before,
  .textGlitch:after{
    content: attr(data-text);
    position: absolute;
    z-index: -1;
  }
  .textGlitch:before{
    right: 0;
  }
  .textGlitch:after{
    left: 0;
  }
  
  .textGlitch:before{
    animation: glitchTop 2.5s linear infinite;
    clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  }
  
  @keyframes glitchTop{
    2%,63%{
      transform: translate(1px,0px);
    }
    4%,60%{
      transform: translate(1px,0px);
    }
    4%,66%{
      transform: translate(0px,0px);
    }
    62%{
      transform: translate(4px,0px) skew(-10deg); 
    }
  }
  
  .textGlitch:after{
    animation: glitchBotom 3s linear infinite;
    clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  }
  
  @keyframes glitchBotom{
    2%,63%{
      transform: translate(0px,0);
    }
    65%{
      transform: translate(0px,0);
    }
    4%,60%{
      transform: translate(-1px,0);
    }
    6%,61%{
      transform: translate(0px,0);
    }
    62%{
      transform: translate(-12px,5px) skew(21deg); 
    }
  }
}